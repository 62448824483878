import React from 'react'
import RodImage from '../components/rodImage'
import '../components/about.css'

const About = () => (
  <div id='about' className='about row'>
    <div className='col-12 col-md-8'>
      <h2 className='section-title m-0' style={{ display: 'inline-block' }}>
        About
      </h2>
      <div
        style={{
          display: 'inline-block',
          height: '1px',
          width: '300px',
          backgroundColor: 'rgb(48, 60, 85)',
          position: 'relative',
          top: '-7px',
          marginLeft: '20px'
        }}
      ></div>
    </div>
    <div className='col-md-6 col-sm-12'>
      <p>
        Hey there! Welcome to my page. My name's Roderick, I am a full stack
        software engineer.
      </p>
      <p>
        My background is rooted in design, I have always been a creative but
        decided to learn how to build things and fell in love with it. I like to
        build products that demonstrate good user experience.
      </p>
      <p>
        After completing the Software Engineering Immersive with Flatiron
        School, I landed my role with{' '}
        <a className='ignis-link' href='https://www.ignis.co.uk/'> Ignis Ltd</a> as a Front End
        Developer where I get to work on a variety of exciting projects.
      </p>
      <p>Recent Technologies:</p>
      <ul className='list-of-tech'>
        <li>• React</li>
        <li>• JavaScript (ES6+)</li>
        <li>• jQuery</li>
        <li>• HTML & (S)CSS</li>
        <li>• Node.js</li>
        <li>• Ruby on Rails</li>
        <li>• WordPress</li>
        <li>• Shopify</li>
      </ul>
    </div>
    <div className='profile-image col-md-4 col-xs-6'>
      <RodImage />
    </div>
  </div>
)

export default About
